import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Main from '../hooks/main';
import { Link } from 'react-router-dom';

import axios from '../api/axios';
// import CollapsibleBox from './CollapsibleBox';
import { Helmet } from 'react-helmet';

function Home() {

    const [successMessage, setSuccessMessage] = useState('');
    const [dangerMessage, setDangerMessage] = useState('');
    const [loading, setloading] = useState(true);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [Phone, setPhone] = useState('');


    const handleSubmit = async (e) => {
        setloading(false);
        setSuccessMessage("");
        setDangerMessage("");
        const data = {
            emailTo: 'contact@queinfotech.com', emailFrom: 'info@queinfotech.com', subjectLine: Subject
            , emailBody: `<p>Name: ${Name}<br>,Email: ${Email}<br>Contact: ${Phone}<br>Message: ${Message}</p>`
        }
        try {
            const response = await axios.post("/email", data);
            setloading(true);
            setSuccessMessage(response.data);
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setPhone("");
        } catch (error) {
            console.log(error);
            setloading(true);
            setDangerMessage(error.message);
        }
    };



    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>IT Solutions and Services | Que Information Technology</title>
                <meta name="description" content="Explore our comprehensive IT solutions and services including Enterprise Solutions, AI & Automation, Data & Analytics, Cybersecurity,IT Managed Services, Microsoft Products, IT Staffing, Cloud Solutions, and Digital Marketing." />
                <meta name="keywords" content=" Que, Que Info Tech, Que Information Technology, IT solutions, Enterprise Solutions, AI & Automation, Data & Analytics, Cybersecurity, Managed Services, Microsoft Products, IT Staffing, Cloud Solutions, Digital Marketing, E-commerce solutions, E-commerce development, E-commerce website design, E-commerce platform, Online store development, E-commerce software, E-commerce integration, Custom E-commerce solutions, E-commerce consulting, E-commerce marketing services, E-commerce strategy, E-commerce analytics, E-commerce maintenance, Mobile E-commerce solutions, E-commerce website optimization, E-commerce SEO services, Shopping cart solutions, E-commerce user experience, E-commerce conversion optimization, E-commerce technology, E-commerce hosting, E-commerce app development, Website development, Web design services, Custom website design, Web development agency, Professional website design, Responsive web design, Web design and development, Creative website design, User-friendly websites, Mobile-responsive websites, Website redesign, Website maintenance, Website optimization, Web development company, Front-end development, Back-end development, Website UI/UX design, WordPress website development, CMS website development, HTML5/CSS3 web design, Website usability, SEO-friendly web design, Website performance optimization, Web design portfolio, Web design, Web development, Website design, Custom website, Responsive design, E-commerce website, WordPress development, CMS development, Web development services, Website design company, Web design agency, Website developer, Professional web design, Mobile-responsive design, User-friendly website, Web development solutions, UI/UX design, HTML/CSS development, Web application development" />

                <meta property="og:title" content="IT Solutions and Services | Que Information Technology" />
                <meta property="og:description" content="Explore our comprehensive IT solutions and services including Enterprise Solutions, AI & Automation, Data & Analytics, Cybersecurity,IT Managed Services, Microsoft Products, IT Staffing, Cloud Solutions, and Digital Marketing." />
                <meta property="og:url" content="https://queinfotech.com/" />
                <meta property="og:type" content="website" />
            </Helmet>


            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h2 data-aos="fade-down" className="pt-5" style={{ fontSize: '40px' }}>
                                    Empowering Your Digital Transformation Journey with Que Info Tech.
                                </h2>
                                <a href="#services" data-aos="fade-up"
                                    data-aos-delay="200"
                                    className="mt-5 btn-get-started" > Get Started </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active" style={{ backgroundImage: 'url(/img/images/12.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/17.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/18.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/8.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/16.jpg)' }}></div>

                    <Link to="#hero-carousel" className="carousel-control-prev" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </Link>

                    <Link to="#hero-carousel" className="carousel-control-next" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </Link>
                </div>
            </section>

            <main id="main">
                <section id="services" className="services section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Que Digital Transformation and Cloud</h2>
                            <p>Transforming Businesses with Cutting-Edge Digital Solutions.</p>
                        </div>

                        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-microsoft"></i>
                                    </div>
                                    <h3>Microsoft Products and Customization</h3>
                                    <p>Leverage the power of Microsoft's suite of products and tailor them to meet your specific business needs.</p>
                                    <Link to="/microsoft" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-laptop"></i>
                                    </div>
                                    <h3>IT Managed Services</h3>
                                    <p>Streamline your IT operations and ensure the security and performance of your systems with our reliable IT managed services.</p>
                                    <Link to="/itmanaged-service" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <h3>IT Staffing</h3>
                                    <p>Access top-notch IT professionals to augment your team and accelerate your projects' success.</p>
                                    <Link to="/itstaffing-service" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-calendar"></i>
                                    </div>
                                    <h3>Enterprise Solution <br />- web 2.0, web 3.0</h3>
                                    <p>Drive innovation and growth with our comprehensive enterprise solutions, leveraging the power of web 2.0 and web 3.0 technologies.</p>
                                    <Link to="/enterprisesolution" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-robot"></i>
                                    </div>
                                    <h3>AI & Automation</h3>
                                    <p>Embrace the power of AI and automation to streamline your business processes and enhance productivity.</p>
                                    <Link to="/ai-and-automation" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-shield-lock"></i>
                                    </div>
                                    <h3>Cybersecurity</h3>
                                    <p>Protect your business and data from cyber threats with our robust cybersecurity solutions.</p>
                                    <Link to="/cybersecurity" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-bar-chart-line"></i>
                                    </div>
                                    <h3>Data & Analytics</h3>
                                    <p>Unlock valuable insights from your data to drive informed decision-making and business growth.</p>
                                    <Link to="/analytics" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-cloud"></i>
                                    </div>
                                    <h3>Cloud Solutions</h3>
                                    <p>Harness the power of the cloud to drive scalability, flexibility, and efficiency for your business.</p>
                                    <Link to="/cloudesolutions" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-megaphone"></i>
                                    </div>
                                    <h3>Digital Marketing Services</h3>
                                    <p>Maximize your online presence and reach your target audience with our comprehensive digital marketing services.</p>
                                    <Link to="/digitalmarketing" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about" className="about pt-0">
                    <div className="container" data-aos="fade-up">
                        <div className="row gx-0">
                            <div className="tab-pane active show">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                        <h3><strong>Why Que Info Tech ?</strong></h3>
                                        <p className="fst-italic">
                                            At Que Info Tech, we empower businesses for success in the digital age. With our comprehensive range of cutting-edge solutions, including enterprise solutions, data analytics, cybersecurity, and AI & automation, we help organizations achieve their digital transformation goals.
                                        </p>
                                        <ul>
                                            <li><strong><i className="bi bi-check2-all"></i> Transform your business with our Smart Business Solutions (SBS)</strong></li>
                                            <li><strong><i className="bi bi-check2-all"></i> Get reliable IT support with our Technology Support Services</strong></li>
                                            <li><strong><i className="bi bi-check2-all"></i> Harness the power of the cloud with our Cloud Solution</strong></li>
                                        </ul>
                                        <p>
                                            As a trusted provider of cutting-edge technologies, we deliver custom solutions tailored to your business needs. Partner with us to drive innovation, growth, and success in the rapidly evolving digital landscape.
                                        </p>
                                    </div>
                                    <div className="col-lg-6 mt-5 pt-3 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                                        <img src="/img/company.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="alt-services-2" className="alt-services bg-light">
                    <div className="container aos-init aos-animate" data-aos="fade-up">

                        <div className="section-header pb-5">
                            <h2>Our Mission</h2>
                        </div>

                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Empowering Businesses and Individuals through Technology</h3>
                                <p>At Que Info Tech, our mission is to leverage technology to empower businesses and individuals, delivering innovative solutions that drive growth and success. We are committed to:</p>

                                <div className="icon-box d-flex position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-check-circle-fill flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Solution Excellence</Link></h4>
                                        <p>We provide comprehensive IT solutions tailored to your specific requirements. Our team of experts will work closely with you to deliver outstanding results and exceed your expectations.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-lightbulb-fill flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Innovation and Technology Leadership</Link></h4>
                                        <p>We stay at the forefront of technological advancements to bring you cutting-edge solutions. With our innovative approach and expertise, we ensure seamless integration and optimized performance for your business.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-award-fill flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Customer Success</Link></h4>
                                        <p>Customer satisfaction is our top priority. We go the extra mile to understand your unique needs and challenges, delivering personalized services and support that drive your success.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 img-bg aos-init aos-animate" style={{ backgroundImage: "url(/img/EFFICIENT.jpg)" }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>
                    </div>
                </section>

                <section id="information" className="information">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Our Vision</h2>
                            <p>
                                Empowering SMEs to harness emerging digital technologies, seize growth opportunities, and thrive in the ever-evolving digital economy.
                            </p>
                        </div>
                        <div className="row gy-4">
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: 'url(/img/CLIENT-CENTRIC.jpg)' }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">CLIENT-CENTRIC</h4>
                                                <p>We satisfy our clients by exceeding their expectations of quality and level of service</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: 'url(/img/INNOVATIVE.jpg)' }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">INNOVATIVE</h4>
                                                <p>We perform research and development efforts in search of innovative solutions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: 'url(/img/RESULTS-DRIVEN.jpg)' }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">RESULTS DRIVEN</h4>
                                                <p>Results driven solutions enabling customers to exceed potential and lower their costs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: 'url(/img/EFFICIENT.jpg)' }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">EFFICIENT</h4>
                                                <p>Solving problems while providing the most efficient and cost saving solutions possible</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="projects" className="projects section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Our Projects</h2>
                            <p>Software development outsourcing is just a tool to achieve business goals. But there is no way
                                to get worthwhile results without cooperation and trust between a client company.</p>
                        </div>

                        <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry"
                            data-portfolio-sort="original-order">

                            <ul className="portfolio-flters" data-aos="fade-up" data-aos-delay="100">
                                <li data-filter="*" className="filter-active">All</li>
                                <li data-filter=".filter-webDevelopment">Web Development</li>
                                <li data-filter=".filter-eCommerc">eCommerce</li>
                                <li data-filter=".filter-mobileDevelopment">Mobile Development</li>
                            </ul>

                            <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">

                                <div className="col-lg-4 col-md-6 portfolio-item filter-eCommerc">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Little-Mountain.png" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Little-Mountain</h4>
                                            <p>Littlemountainoutdoors is a platform to help people find the required things for camping, clothes, and many more things.</p>
                                            <Link to="/img/projects/Little-Mountain.png" title="Little-Mountain" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="https://littlemountainoutdoors.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-webDevelopment">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Anurag.png" className="img-fluid" alt="Anurag Urban Tech Infra" />
                                        <div className="portfolio-info">
                                            <h4>Anurag Urban Tech Infra</h4>
                                            <p>Anurag Urban Tech Infra is a marketplace to help people find houses, flats, and build their own houses.</p>
                                            <Link to="/img/projects/Anurag.png" title="Anurag" data-gallery="portfolio-gallery-construction" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="http://anuragutpl.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-mobileDevelopment">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/DrinkME.png" className="img-fluid" alt="DrinkME" />
                                        <div className="portfolio-info">
                                            <h4>DrinkME</h4>
                                            <p>The judge can give ratings to participants, and the user can view participants, judge, and event information.</p>
                                            <Link to="/img/projects/DrinkME.png" title="DrinkME" data-gallery="portfolio-gallery-electric" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="https://www.behance.net/gallery/123635411/DrinkME-App" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-eCommerc">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/medeb.png" className="img-fluid" alt="Medeb" />
                                        <div className="portfolio-info">
                                            <h4>Medeb</h4>
                                            <p>Medeb helps people find Homeware, Leather Products, Pottery, Traditional Grass Crafts.</p>
                                            <Link to="/img/projects/medeb.png" title="medeb" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="https://medeb.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-webDevelopment">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Axon.png" className="img-fluid" alt="Axon Advisors" />
                                        <div className="portfolio-info">
                                            <h4>Axon Advisors</h4>
                                            <p>Axon Advisors is a marketplace to help people grow their business skills and tap into expertise across all sectors and industries.</p>
                                            <Link to="/img/projects/Axon.png" title="axonadvisors" data-gallery="portfolio-gallery-construction" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="https://www.axonadvisors.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-mobileDevelopment">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Pick-n-Go.png" className="img-fluid" alt="Pick-n-Go" />
                                        <div className="portfolio-info">
                                            <h4>Pick-n-Go</h4>
                                            <p>Food order pickup and delivery app - It provides businesses with a drive-thru element while offering the capability to generate on-the-fly and daily deals to target customers.</p>
                                            <Link to="/img/projects/Pick-n-Go.png" title="Pick-n-Go" data-gallery="portfolio-gallery-repairs" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="project-details.html#Pick-n-Go" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-eCommerc">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Cosy-House.png" className="img-fluid" alt="Cosy-House" />
                                        <div className="portfolio-info">
                                            <h4>Cosy-House</h4>
                                            <p>Various types of bed sheets available. Users can purchase bed sheets for babies, kids, adults, etc.</p>
                                            <Link to="/img/projects/Cosy-House.png" title="Cosy-House" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="https://cosyhouse.eu/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-webDevelopment">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Bewear.png" className="img-fluid" alt="Bewearws" />
                                        <div className="portfolio-info">
                                            <h4>Bewearws</h4>
                                            <p>Bewearws Web Application</p>
                                            <Link to="/img/projects/Bewear.png" title="Bewearws" data-gallery="portfolio-gallery-construction" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="https://www.bewear-werkkleding.nl/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-mobileDevelopment">
                                    <div className="portfolio-content h-100">
                                        <img src="/img/projects/Tailgate.png" className="img-fluid" alt="Sports Tailgates gathering app" />
                                        <div className="portfolio-info">
                                            <h4>Tailgate</h4>
                                            <p>Sports Tailgates gathering app</p>
                                            <Link to="/img/projects/Tailgate.png" title="Tailgate" data-gallery="portfolio-gallery-repairs" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
                                            <Link to="project-details.html#Tailgate" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></Link>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <section id="client" className="client py-5">
                    <div className="container" data-aos="fade-down">
                        <div className="section-header text-center">
                            <h2>Trusted By Enterprises Across the Globe</h2>
                        </div>

                        <div className="row justify-content-center align-items-center text-center" data-aos="fade-up" data-bs-interval="300">
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-geopell-cloud.png" alt="Geopell cloud" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-thinkbiz.png" alt="Thinkbiz" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-ministry-of-health.png" alt="Ministry Of Health" className="img-fluid p-3" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-ksu.png" alt="King Saud University" className="img-fluid pt-3" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-OMCT.png" alt="Orient MCT" className="img-fluid p-3" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-heartymart.png" alt="Hearty Mart" className="img-fluid pt-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="testimonials section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>See why customers love Que Info Tech</h2>
                        </div>

                        <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item ">
                                                {/* <img src="/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>M Darwish</h3>
                                                {/* <h4>Ceo &amp; Founder</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    Que Infotech has truly transformed our business with their exceptional service.
                                                    Their expertise and dedication have been invaluable to our success..
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                {/* <img src="/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>Berleys</h3>
                                                {/* <h4>Designer</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    We are extremely pleased with the deliverables. They are spot on to what we required. Cheers!
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* End testimonial item */}
                                </div>
                                <div className="carousel-item">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                {/* <img src="/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>Jennifer Rosen</h3>
                                                {/* <h4>Store Owner</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    The UX of the app was outstanding, to say the least. I highly commend their app designers for the same.
                                                    They understood what we required in a jiffy. Great work!
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* End testimonial item */}
                                </div>
                            </div>
                            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button> */}
                        </div>

                    </div>
                </section>

                <section id="get-started" className="get-started section">
                    <div className="container">
                        <div className="row justify-content-between gy-4">
                            <div className="text-center">
                                <h1>Let's Work Together!</h1>
                                <p>We love working with new people and companies. Please take a moment to tell us about your project.<br />
                                    Your messages will be responded to within ONE BUSINESS DAY.</p>
                            </div>

                            <div className="col-md-6 col-lg-6 d-none d-sm-block text-left">
                                <div className="row pb-0 pt-0 p-5">
                                    <div className="col-6 pb-0 pt-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0">
                                                <img src="/img/icons/call-support.svg" alt="Support" style={{ maxWidth: '60%' }} className="img-fluid w-size-60 mb-2" />
                                                <h6>Call Enquiry Assistance</h6>
                                                <p style={{ color: '#65737e', fontSize: '16px' }}>We are always available on call to assist you with any questions you may have.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-0 pt-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0">
                                                <img src="/img/icons/domain-experts.svg" alt="Project Consultation" style={{ maxWidth: '60%' }} className="w-size-60 mb-2" />
                                                <h6>Project Consultation</h6>
                                                <p style={{ color: '#65737e', fontSize: '16px' }}>We provide you with all technical consultations for your project.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-0 pb-0 p-5">
                                    <div className="col-6 mb-3 pt-0 pb-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0">
                                                <img src="/img/icons/estimation.svg" alt="Project Estimation" style={{ maxWidth: '60%' }} className="w-size-60 mb-2" />
                                                <h6>Project Estimation</h6>
                                                <p className="m-0" style={{ color: '#65737e', fontSize: '16px' }}>You come to us with your project, and we do all of the planning and costing for you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3 pt-0 pb-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0">
                                                <img src="/img/icons/schedule.svg" alt="Schedule Your Appointment" style={{ maxWidth: '60%' }} className="w-size-60 mb-2" />
                                                <h6>Schedule Your Appointment</h6>
                                                <p className="m-0" style={{ color: '#65737e', fontSize: '16px' }}>We assist you in scheduling appointments whenever you wish to meet us.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5" data-aos="fade">
                                <div className="php-email-form">
                                    <h3>GET IN TOUCH</h3>
                                    <p>Send us your requirements and we'll get back to you at the earliest.</p>
                                    <div className="row gy-3 form-group">
                                        <div className="col-md-12">
                                            <input type="text" name="name" className="form-control" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <input type="email" className="form-control" name="email" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <input type="text" className="form-control" name="contact" placeholder="Phone" value={Phone} onChange={(e) => setPhone(e.target.value)} required />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={Subject} onChange={(e) => setSubject(e.target.value)} required />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <textarea className="form-control" name="message" rows="6" placeholder="Message" value={Message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="justify-content-center" hidden={loading}>
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                            <div className="alert alert-danger" role="alert" hidden={!dangerMessage}>{dangerMessage}</div>
                                            <div className="alert alert-success" role="alert" hidden={!successMessage}>{successMessage}</div>
                                            <button type="submit" onClick={handleSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );

}

export default Home;