import useAuth from "./useAuth";


const useAuthorization = () => {
    const {auth} = useAuth();

    const isAuthorized = (allowedRoles) => {
        return auth?.roles?.find(role => allowedRoles?.includes(role)) 
        ? true 
        : false
        ;
    };
  
    return [isAuthorized];
  };


export default useAuthorization

//import { useState} from 'react';

// const useAuthorization = (initialState) => {
//     //const [state, setState] = useState(initialState);
//     const {auth} = useAuth();

//     const handleToggle = (allowedRoles) => {
//         //value: This is received value from page;
//         //auth?.roles: This is received from auth;
//         let returnValue = false;
//         returnValue = auth?.roles?.find(role => allowedRoles?.includes(role)) 
//         ? true 
//         : false
//         ;

//         return returnValue;
//     };
  
//     return [
//         {
//           setToggle: handleToggle,
//         },
//       ];

//     // return [
//     //   state,
//     //   {
//     //     setToggle: handleToggle,
//     //   },
//     // ];
//   };



// const useAuthorization= async ({ allowedRoles })=>{
//     const [state, setState] = useState(false);
//     const {auth} = useAuth();

//     const isMyAccess = () => {
//         setState(
//             auth?.roles?.find(role => allowedRoles?.includes(role))
//         )
//     }
    
//     return [state,
//               {
//                 setIsMyAccess: isMyAccess,
//               },];
// }