import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useLogout from '../hooks/useLogout';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuthorization from "../hooks/useAuthorization";
import '../App.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.min.css';



// const ROLES = {
//   'User': 3,
//   'Editor': 2,
//   'Administrator': 1
// }

const Header = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isAuthorized] = useAuthorization();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  const [fullname, setFullName] = useState('');


  const [isShowDanger, setIsShowDanger] = useState(false);
  const [MessageDanger, setMessageDanger] = useState("");

  const logout = useLogout();

  const controller = new AbortController();
  const getUser = async () => {
    try {
      const response = await axiosPrivate.get(`/user/${auth?.userId}`);
      const firstName = response?.data[0]?.FirstName;
      const lastName = response?.data[0]?.LastName;
      const fullName = `${firstName}${lastName ? ' ' + lastName : ''}`;
      setFullName(fullName);
    } catch (err) {
      console.error(err);
      setIsShowDanger(true);
      if (!err?.response) {
        setMessageDanger('No Server Response');
      } else if (err.response?.status === 400) {//Required field missing
        setMessageDanger(`Missing: ${err}`);
      } else if (err.response?.status === 401) { //Unauthorized
        setMessageDanger(`Unauthorized: ${err}`);
        navigate('/login', { state: { from: location }, replace: true });
      } else {
        setMessageDanger(`Error in get user by id API call: ${err}`);
      }
    }
  }

  const signout = async () => {
    await logout();
    navigate('/Login');
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && AOS.init();
    if (auth?.userId) {
      isMounted && getUser();
    }
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [auth?.userId]);


  const [isMobileNavActive, setMobileNavActive] = useState(false);

  const mobileNavToggle = () => {
    setMobileNavActive(!isMobileNavActive);
  };

  const closeMobileNav = () => {
    setMobileNavActive(false);
  };

  const handleNavbarLinkClick = () => {
    if (isMobileNavActive) {
      closeMobileNav();
    }
  };

  const handleDropdownClick = event => {
    if (isMobileNavActive) {
      event.preventDefault();
      const target = event.currentTarget;
      const dropdownIndicator = target.querySelector('.dropdown-indicator');

      if (dropdownIndicator) {
        target.classList.toggle('active');
        target.nextElementSibling.classList.toggle('dropdown-active');

        dropdownIndicator.classList.toggle('bi-chevron-up');
        dropdownIndicator.classList.toggle('bi-chevron-down');
      }
    }
  };


  useEffect(() => {
    const onScroll = (el) => {
      const header = document.querySelector('#header');
      let offset = header.offsetHeight;

      if (!header.classList.contains('header-scrolled')) {
        offset -= 20;
      }

      const element = document.querySelector(el);
      const elementPos = element.offsetTop;

      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      });
    };

    const headerScrolled = () => {
      const selectHeader = document.querySelector('#header');
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled');
      } else {
        selectHeader.classList.remove('header-scrolled');
      }
    };

    window.addEventListener('load', headerScrolled);
    window.addEventListener('scroll', headerScrolled);

    return () => {
      window.removeEventListener('load', headerScrolled);
      window.removeEventListener('scroll', headerScrolled);
    };
  }, []);

  return (
    <>
      <header id="header" className={`pb-2 pt-2 d-flex align-items-center fixed-top ${isMobileNavActive ? 'mobile-nav-active' : ''}`}>
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

          <Link to="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img src="/img/Company/QUE INFOTECH.png" alt="Que Information Technology" width="170px" height="52px"
              className="mt-0" />
            {/* <h1>Que Information Technology<span>.</span></h1> */}
          </Link>

          <i className={`mobile-nav-toggle mobile-nav-show bi bi-list ${isMobileNavActive ? 'd-none' : ''}`} onClick={mobileNavToggle}></i>
          <i className={`mobile-nav-toggle bi bi-x mb-3 ${isMobileNavActive ? 'mobile-nav-show' : 'mobile-nav-hide d-none'}`} onClick={mobileNavToggle}></i>

          <nav id="navbar" className="navbar">
            <ul>
              {/* <li><Link to="" href="index.html" className="active">Home</Link></li> */}
              {/* <li><Link to="" href="services.html"></Link></li> */}
              <li className="dropdown"><Link to="#" onClick={handleDropdownClick}><span>Company</span> <i
                className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li><Link to="/aboutus" onClick={handleNavbarLinkClick}>About</Link></li>
                  <li><Link to="/career" onClick={handleNavbarLinkClick}>Career</Link></li>
                </ul>
              </li>
              <li className="dropdown"><Link to="#" onClick={handleDropdownClick}><span>Services</span> <i
                className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li className="dropdown"><Link to="#" onClick={handleDropdownClick}><span>Technology Support Services</span> <i className="bi bi-chevron-right dropdown-indicator"></i></Link>
                    <ul>
                      <li><Link to="/microsoft" onClick={handleNavbarLinkClick}>Microsoft Products and Customization</Link></li>
                      <li><Link to="/itmanaged-service" onClick={handleNavbarLinkClick}>IT Managed Services</Link></li>
                      <li><Link to="/itstaffing-service" onClick={handleNavbarLinkClick}>IT Staffing</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown"><Link to="#" onClick={handleDropdownClick}><span>Smart Business Solutions (SBS)</span> <i className="bi bi-chevron-right dropdown-indicator"></i></Link>
                    <ul>
                      <li><Link to="/enterprisesolution" onClick={handleNavbarLinkClick}>Enterprise Solution</Link></li>
                      <li><Link to="/ai-and-automation" onClick={handleNavbarLinkClick}>AI & Automation</Link></li>
                      <li><Link to="/cybersecurity" onClick={handleNavbarLinkClick}>Cybersecurity</Link></li>
                      <li><Link to="/analytics" onClick={handleNavbarLinkClick}>Data & Analytics</Link></li>
                      <li><Link to="mobile-app-development" onClick={handleNavbarLinkClick}>Mobile App Development</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/cloudesolutions" onClick={handleNavbarLinkClick}>Cloud Solutions</Link></li>
                  <li><Link to="/digitalmarketing" onClick={handleNavbarLinkClick}>Digital Marketing Services</Link></li>
                </ul>
              </li>
              <li className="dropdown"><Link to="/" onClick={handleDropdownClick}><span>Products</span> <i
                className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li><Link to="/crm" onClick={handleNavbarLinkClick}>Customer Relationship Management (CRM)</Link></li>
                  <li><Link to="/contactus" onClick={handleNavbarLinkClick}>Content Management System (CMS)</Link></li>
                  <li><Link to="/contactus" onClick={handleNavbarLinkClick}>Accounting System</Link></li>
                  <li><Link to="/contactus" onClick={handleNavbarLinkClick}>Warehouse Management System</Link></li>
                </ul>
              </li>
              {/* <li><Link to="/" onClick={handleNavbarLinkClick}>Customer</Link></li>  TODO: Make Customer Page*/} 
              <li><Link to="/contactus" onClick={handleNavbarLinkClick}>Contact</Link></li>
              {
                auth?.userId
                  ?
                  <li className="dropdown"><Link to="#" onClick={handleDropdownClick}><span>{fullname}</span> <i
                    className="bi bi-chevron-down dropdown-indicator"></i></Link>
                    <ul>
                      {/* <li><Link to="/myprofile" >My Profile</Link></li>
                      <li><Link to="/aboutus">About Us</Link></li>
                      <hr className="m-1" /> */}
                      <li><Link to="/login" onClick={signout} >Logout</Link></li>
                      <li><Link to="/myprofile" onClick={handleNavbarLinkClick}>My Profile</Link></li>
                    </ul>
                  </li>
                  :
                  <li><Link className="navbar-brand" to="/login" onClick={handleNavbarLinkClick}>Login</Link></li>
              }
            </ul>
          </nav>{/* .navbar */}

        </div>
      </header>{/* End Header */}
    </>
  );
};

export default Header;
